import React from "react";
import "./index.css";

const RentVsOwnCalculator = () => {
  return (
    <div className="rent-vs-own-calculator-wrapper">
      <iframe
        className="rent-vs-own-calculator"
        height="100%"
        width="100%"
        title="RentVsOwnCalculator"
        id="KJE-IFRAME"
        src="./rentVsOwnCalculator.html"
      ></iframe>
    </div>
  );
};

export default RentVsOwnCalculator;
