import DinkyTownCalculator from "../../components/DinkyTownCalculator";

const HomeClosingCostCalculator = () => {
  return (
    <DinkyTownCalculator
      src="https://www.dinkytown.net/v3/970880/BuyerCostEstimate.html"
      title="HomeClosingCostCalculator"
      id="HomeClosingCostCalculator"
    />
  );
};

export default HomeClosingCostCalculator;
