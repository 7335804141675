import DinkyTownCalculator from "../../components/DinkyTownCalculator";

const HomeBudgetAnalysisCalculator = () => {
  return (
    <DinkyTownCalculator
      src="https://www.dinkytown.net/v3/970880/HomeBudget.html"
      title="HomeBudgetAnalysisCalculator"
      id="HomeBudgetAnalysisCalculator"
    />
  );
};

export default HomeBudgetAnalysisCalculator;
