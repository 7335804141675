import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MortgageCalculator from "../pages/mortgage";
import RentVsOwnCalculator from "../pages/rent-vs-own";
import HomeBudgetAnalysisCalculator from "../pages/home-budget-analysis";
import HomeClosingCostCalculator from "../pages/home-closing-cost";
import MortgagePointsCalculator from "../pages/mortgage-points";
import MortgageRefinanceCalculator from "../pages/mortgage-refinance";
import MortgageTaxSavingsCalculator from "../pages/mortgage-tax-savings";
import PersonalDebtConsolidationCalculator from "../pages/personal-debt-consolidation";
import CreditCardPayOffCalculator from "../pages/credit-card-pay-off";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/mortgage" />} />
          <Route path="/mortgage" element={<MortgageCalculator />} />
          <Route path="/rent-vs-own" element={<RentVsOwnCalculator />} />
          <Route
            path="/home-budget-analysis"
            element={<HomeBudgetAnalysisCalculator />}
          />
          <Route
            path="/home-closing-cost"
            element={<HomeClosingCostCalculator />}
          />
          <Route
            path="/mortgage-points"
            element={<MortgagePointsCalculator />}
          />
          <Route
            path="/mortgage-refinance"
            element={<MortgageRefinanceCalculator />}
          />
          <Route
            path="/mortgage-tax-savings"
            element={<MortgageTaxSavingsCalculator />}
          />
          <Route
            path="/personal-debt-consolidation"
            element={<PersonalDebtConsolidationCalculator />}
          />
          <Route
            path="/credit-card-pay-off"
            element={<CreditCardPayOffCalculator />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
