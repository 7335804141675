import DinkyTownCalculator from "../../components/DinkyTownCalculator";

const MortgagePointsCalculator = () => {
  return (
    <DinkyTownCalculator
      src="https://www.dinkytown.net/v3/970880/MortgagePoints.html"
      title="MortgagePointsCalculator"
      id="MortgagePointsCalculator"
    />
  );
};

export default MortgagePointsCalculator;
