import DinkyTownCalculator from "../../components/DinkyTownCalculator";

const CreditCardPayOffCalculator = () => {
  return (
    <DinkyTownCalculator
      src="https://www.dinkytown.net/v3/970880/PayoffCC.html"
      title="CreditCardPayOffCalculator"
      id="CreditCardPayOffCalculator"
    />
  );
};

export default CreditCardPayOffCalculator;
