import DinkyTownCalculator from "../../components/DinkyTownCalculator";

const MortgageRefinanceCalculator = () => {
  return (
    <DinkyTownCalculator
      src="https://www.dinkytown.net/v3/970880/MortgageRefinance.html"
      title="MortgageRefinanceCalculator"
      id="MortgageRefinanceCalculator"
    />
  );
};

export default MortgageRefinanceCalculator;
