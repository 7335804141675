import DinkyTownCalculator from "../../components/DinkyTownCalculator";

const PersonalDebtConsolidationCalculator = () => {
  return (
    <DinkyTownCalculator
      src="https://www.dinkytown.net/v3/970880/DebtConsolidate.html"
      title="PersonalDebtConsolidationCalculator"
      id="PersonalDebtConsolidationCalculator"
    />
  );
};

export default PersonalDebtConsolidationCalculator;
