import "./index.css";

const DinkyTownCalculator = ({ src = "", title = "", id = "" }) => {
  return (
    <div className="dinky-town-calculator-wrapper">
      <iframe
        className="dinky-town-calculator"
        height="100%"
        width="100%"
        title={title}
        id={id}
        src={src}
      ></iframe>
    </div>
  );
};

export default DinkyTownCalculator;
